<template>
  <div class="workspace">
    <div class="row">
      <div class="flex md12 py-0">
        <va-tabs
          center
          v-model="tabValue"
          style="width: 100%; min-width: 250px;"
        >
          <template #tabs>
            <va-tab>
              Form
            </va-tab>
            <va-tab>
              Response
            </va-tab>
            <va-tab>
              Share
            </va-tab>
          </template>
        </va-tabs>
      </div>
    </div>

    <div class="row">
      <div style="padding: 0px;" class="flex md12">
        <va-card>
          <va-card-content>
            <div class="row mt-2">
              <div class="header-card ml-auto mr-3">
                <div class="flex text-center">
                  <div class="mb-2"><h1>20 Sep 2021</h1></div>
                  <div>Publikasi terakhir</div>
                </div>
              </div>
              <div class="header-card mr-3">
                <div class="flex text-center">
                  <div class="mb-2"><h1>20</h1></div>
                  <div>Dilihat</div>
                </div>
              </div>
              <div class="header-card mr-auto">
                <div class="flex text-center">
                  <div class="mb-2"><h1>2,050</h1></div>
                  <div>Tanggapan</div>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div>
                <va-tabs
                  center
                  v-model="tabValue"
                  style="width: 100%; min-width: 250px;"
                >
                  <template #tabs>
                    <va-tab>
                      Summary
                    </va-tab>
                    <va-tab>
                      Details
                    </va-tab>
                  </template>
                </va-tabs>
              </div>
              <div class="ml-auto mr-4">
                <va-button class="mr-2" color="danger">Close Survey</va-button>
                <va-button color="success">Download Survey</va-button>
              </div>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>

    <div class="row">
      <div class="flex md12">
        <va-card>
          <va-card-content class="px-4 py-4">
            <div>
              <h1>Application to the Toko launch pad</h1>
            </div>
            <div class="mt-2">
              <span
              >In order to be considered for our platform, all candidates and
                their projects will be thoroughly vetted. Please fill out the
                following application to the best of your ability and our team
                will contact you with further details.</span
              >
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabValue: 1
    };
  }
};
</script>

<style lang="scss" scoped>
.header-card {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 16px;
  min-width: 200px;
}
</style>
